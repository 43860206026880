import React, { useEffect } from 'react'
import 'twin.macro'
import { StaticImage } from 'gatsby-plugin-image'
import { useAnimation, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import { revealVariants } from '../../../utils/motion'
import useMediaQuery from '../../../hooks/useMediaQuery'
import CollectionNormalCake from './CollectionNormalCake'
import CollectionTulipCake from './CollectionTulipCake'
// import { graphql, useStaticQuery } from 'gatsby'

const AbsoluteStyle = {
  position: 'absolute',
  minWidth: '100px',
  minHeight: '100px',
  height: 'auto',
  zIndex: 0,
  overflowY: 'visible',
  aspectRatio: 'auto',
}

const TulipRight = () => {
  return (
    <StaticImage
      src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-20-10/2023/tulipRight.png"
      alt="ảnh hoa tulip"
      placeholder="blurred"
      style={{ ...AbsoluteStyle, width: '11%', top: '3rem', right: 0 }}
    />
  )
}
const TulipRightBot = () => {
  return (
    <StaticImage
      src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-20-10/2023/tulipRight.png"
      alt="ảnh hoa tulip"
      placeholder="blurred"
      style={{ ...AbsoluteStyle, width: '15%', bottom: '32%', right: 0 }}
    />
  )
}
const TulipLeft = () => {
  return (
    <StaticImage
      src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-20-10/2023/tulipLeft.png"
      alt="ảnh hoa tulip"
      placeholder="blurred"
      style={{ ...AbsoluteStyle, width: '15%', top: '42%', left: 0 }}
    />
  )
}
const Cloud1 = () => {
  return (
    <StaticImage
      src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-20-10/2023/cloud.png"
      alt="ảnh hoa tulip"
      placeholder="blurred"
      style={{
        ...AbsoluteStyle,
        width: '100%',
        top: 0,
      }}
    />
  )
}
const Cloud2 = () => {
  return (
    <StaticImage
      src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-20-10/2023/cloud.png"
      alt="ảnh hoa tulip"
      placeholder="blurred"
      style={{ ...AbsoluteStyle, width: '100%', bottom: '10%' }}
    />
  )
}

const TulipCollection = () => {
  // const query = graphql`
  //   {
  //     imgDataTuiBanhTulip: allS3Object(
  //       filter: {
  //         Key: {
  //           regex: "/images/products/banh-sinh-nhat/tui-banh-kem-tulip-mau-hong/"
  //         }
  //       }
  //       sort: { fields: [Key], order: ASC }
  //     ) {
  //       totalCount
  //       edges {
  //         node {
  //           Key
  //           localFile {
  //             name
  //             childrenImageSharp {
  //               gatsbyImageData(width: 800, height: 900)
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `
  // const { imgDataTuiBanhTulip } = useStaticQuery(query)

  const controls = useAnimation()
  const [ref, inView] = useInView()
  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  const isMediumScreen = useMediaQuery('(min-width: 768px)')
  return (
    <motion.div
      ref={ref}
      variants={revealVariants}
      initial="hidden"
      animate={controls}
    >
      <div tw="relative mb-10 z-10" id="collection">
        {isMediumScreen && (
          <>
            <Cloud1 />
            <Cloud2 />
            <TulipLeft />
            <TulipRightBot />
          </>
        )}
        <TulipRight />
        <div
          tw="relative z-20 max-w-5xl mx-2 sm:mx-5
          lg:mx-auto text-center space-y-6 pb-6
          md:space-y-10 md:pb-10
          sm:pt-8"
        >
          <h1 tw="font-savor-cake-primary-lexend font-bold text-4xl md:text-5xl text-savor-cake-pink-400">
            Bộ sưu tập bánh kem 20-10
          </h1>
          <p
            tw="font-savor-cake-secondary-quicksand font-medium text-sm md:text-2xl
          text-20t10-red-1000 px-6 md:px-40"
          >
            Savor Cake ra mắt bộ sưu tập bánh cực xinh xắn phù hợp làm quà tặng
            cho Ngày Phụ Nữ Việt Nam
          </p>
          <CollectionNormalCake />
          <CollectionTulipCake />
          {/* <CollectionNormalCake
            productId={'tui-banh-kem-tulip-mau-hong'}
            reverse={true}
            imgData={imgDataTuiBanhTulip}
          /> */}
        </div>
      </div>
    </motion.div>
  )
}

export default TulipCollection
