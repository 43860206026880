import { styled } from 'twin.macro'
import * as React from 'react'
import { useEffect } from 'react'
import { Link } from 'react-scroll'
import TopMenu from './TopMenu'
import { StaticImage } from 'gatsby-plugin-image'
import { useAnimation, motion } from 'framer-motion'
import { revealVariants, buttonVariants } from '../../utils/motion'
import mobileBg from '../../assets/images/landing-page-20-10/2023/heroBgMobi.png'
import desktopBg from '../../assets/images/landing-page-20-10/2023/heroBg.png'
import { useInView } from 'react-intersection-observer'

export default function HeroSection() {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  const menuItems = [
    { label: 'Bộ sưu tập bánh 20/10', id: 'collection' },
    // { label: 'Phụ kiện quà tặng', id: 'bo-hoa-35k' },
    {
      label: 'Chính sách Ship và thanh toán',
      id: 'policy',
    },
    { label: 'Cơ sở sẵn bánh', id: 'google-map' },
    { label: 'Feedback', id: 'feedback' },
  ]
  return (
    <motion.div
      ref={ref}
      variants={revealVariants}
      initial="hidden"
      animate={controls}
    >
      <HeroSectionWrapper tw="">
        <TopMenu menuItems={menuItems}></TopMenu>
        <div div tw="p-0 mx-auto 2xl:pt-8 lg:container">
          <div
            tw="flex flex-col
            mx-auto overflow-hidden
            "
          >
            {/* title */}
            <div
              tw="mt-16 sm:mt-24
              mx-4 xs:mx-10 sm:mx-12 lg:mx-48 xl:mx-56 2xl:mx-72"
            >
              <div tw="lg:hidden overflow-visible">
                <StaticImage
                  src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-20-10/2023/heroTitleMobi.png"
                  alt="heading"
                />
              </div>
              <div tw="hidden lg:block">
                <StaticImage
                  src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-20-10/2023/heroTitle.png"
                  alt="heading"
                />
              </div>
            </div>
            <div tw="mt-4 lg:hidden mx-16 xs:mx-24 sm:mx-32 md:mx-44">
              <StaticImage
                src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-20-10/2023/heroPriceTag.png"
                alt="priceTag"
              />
            </div>
            {/* image */}
            <div
              tw="
              lg:col-span-6 lg:row-span-3 lg:row-start-2 lg:col-start-7
              m-auto
              w-9/10 sm:w-4/5 lg:w-9/10 xl:w-4/5
              pt-8
              "
            >
              <div tw="lg:hidden ml-10 xs:ml-16">
                <StaticImage
                  src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-20-10/2023/heroCakes2010.png"
                  alt="bánh 8/3"
                ></StaticImage>
              </div>
              <div tw="hidden lg:block lg:ml-32 xl:ml-36">
                <StaticImage
                  src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-20-10/2023/heroCakes2010.png"
                  alt="bánh 8/3"
                ></StaticImage>
              </div>
            </div>
            {/* button */}
            <div tw="lg:col-start-1 lg:col-span-6 flex justify-center w-full -mt-10 md:-mt-20 z-20">
              <motion.div variants={buttonVariants} whileHover="hover">
                <Link smooth={true} to="collection">
                  <button
                    tw="row-span-1 row-start-5 w-64 h-14 xl:w-80 xl:h-18
                    text-white text-xl sm:text-2xl xl:text-3xl
                      rounded-100 border-0
                      bg-linear-gradient-pink-100
                      m-auto
                      font-savor-cake-secondary-quicksand font-bold!
                      mb-12 mt-8 sm:mt-12 lg:mt-8
                      lg:col-span-6 lg:row-span-1 lg:col-start-1
                      cursor-pointer
                      uppercase
                    "
                  >
                    đặt bánh ngay
                  </button>
                </Link>
              </motion.div>
            </div>
          </div>
        </div>
      </HeroSectionWrapper>
    </motion.div>
  )
}

const HeroSectionWrapper = styled.div`
  background-image: url(${mobileBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media screen and (min-width: 1024px) {
    background-image: url(${desktopBg});
  }
`
