import 'twin.macro'
import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import TailwindLayout from '../components/Layouts/TailwindLayout'
import HeroSection from '../components/Banh20T10Page/HeroSection'
import FeatureSection from '../components/Banh20T10Page/FeatureSection'
import CollectionSection from '../components/Banh20T10Page/Collection/CollectionSection'
import PolicySection from '../components/Banh20T10Page/PolicySection'
import FeedbackSection from '../components/Banh8T3Page/FeedbackSection'
import Footer from '../components/Base/Footer'
import SEOComponent from '../components/SEOComponent'
import CakeLocations from '../components/Locations'
import { cakeLocations, outletConfigs } from '../utils/locations'
import { siteUrl } from '../utils/constants'
export default function Banh20T10Page() {
  const dataFeedback = useStaticQuery(graphql`
    {
      allS3Object(
        #  filter: { Key: { regex: "/fb-banh201023/" } }
        filter: { Key: { regex: "/fb/" } }
        sort: { fields: [Key], order: ASC }
      ) {
        totalCount
        edges {
          node {
            Key
            localFile {
              name
              childrenImageSharp {
                gatsbyImageData(width: 800, height: 800)
              }
            }
          }
        }
      }
    }
  `)
  return (
    <TailwindLayout tw="bg-white">
      <HeroSection />
      <FeatureSection />
      <CollectionSection />
      <PolicySection />
      <CakeLocations
        locationData={cakeLocations}
        outletConfigs={outletConfigs}
        theme={'pink'}
        pageName="2010"
        bgColor="linear-gradient(-135deg, #FFD8DF 10%, rgba(255, 216, 223, 0.00) 95.78%);"
      />
      <FeedbackSection data={dataFeedback} />
      <Footer bgColor={'#FE959A'} />
      <SEOComponent
        title="Savor | Bánh kem 20/10 mừng ngày Phụ nữ Việt Nam"
        description="Lựa chọn các mẫu bánh kem ngày phụ nữ Việt Nam 20/10 chất lượng và ý nghĩa với mức giá hợp lý chỉ từ 180k."
        keywords="bánh 20/10"
        altUrl={`${siteUrl}/2010`}
      />
    </TailwindLayout>
  )
}
