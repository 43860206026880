import 'twin.macro'
import * as React from 'react'
import { get, find, head } from 'lodash'
import { vnWomen20t10Collection } from '../../../utils/products'
import tracker from '../../../utils/tracker'
import { useAnimation, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { revealVariants } from '../../../utils/motion'
import {
  DescComponent,
  SizeComponent,
  PriceComponent,
  ColLeft,
  ColRight,
  OrderButton,
  CakeContainer,
} from './CollectionElems'
import { successToast } from '../../../utils/toastify'
import useOrderItems from '../../../hooks/useOrderItems'
import CollectionSlider from './CollectionSlider'

export default function CollectionSection({ productId, reverse, imgData }) {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  const { addToCart } = useOrderItems()

  React.useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  const product = find(vnWomen20t10Collection, { id: productId })
  const [selectedVariant, setSelectedVariant] = React.useState(
    head(get(product, 'variants'))
  )
  const handleSelectedVariant = (variant) => {
    setSelectedVariant(variant)
  }

  const handleAddToCart = () => {
    tracker.addToCart({ value: selectedVariant.salePrice })
    addToCart({ product, selectedVariant })
    successToast('🎉 Thêm sản phẩm vào giỏ hàng thành công')
  }
  return (
    <motion.div
      ref={ref}
      variants={revealVariants}
      initial="hidden"
      animate={controls}
    >
      {product && (
        <div tw="relative pb-2">
          <CakeContainer>
            <ColLeft reverse={reverse}>
              <CollectionSlider imgData={imgData} />
            </ColLeft>
            <ColRight reverse={reverse}>
              <DescComponent product={product} />
              <SizeComponent
                product={product}
                selectedVariant={selectedVariant}
                handleSelectedVariant={handleSelectedVariant}
              />
              <PriceComponent selectedVariant={selectedVariant} />

              <OrderButton
                sku={selectedVariant.sku}
                contentActive="Thêm vào giỏ"
                contentComing="From 18/10"
                handleAddToCart={handleAddToCart}
              />
            </ColRight>
          </CakeContainer>
        </div>
      )}
    </motion.div>
  )
}

CollectionSection.defaultProps = {
  productId: 'banh-kem-tulip-ruy-bang-voan-hong',
  reverse: false,
}
