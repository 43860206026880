import React, { useEffect, useState } from 'react'
import 'twin.macro'
import { find, get, filter } from 'lodash'
import { Link } from 'gatsby'
import { vnWomen20t10Collection } from '../../../utils/products'
import { useAnimation, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { revealVariants } from '../../../utils/motion'
import {
  DescComponent,
  SizeComponent,
  PriceComponent,
  ColLeft,
  ColRight,
  OrderButton,
  VariantStyled,
  CakeContainer,
} from './CollectionElems'
// import tracker from '../../../utils/tracker'
// import { successToast } from '../../../utils/toastify'
// import useOrderItems from '../../../hooks/useOrderItems'
import CollectionSlider from './CollectionSlider'

const tulipCakes =
  filter(vnWomen20t10Collection, (product) => {
    return (
      product.id === 'banh-kem-bo-hoa-tulip-mau-hong' ||
      product.id === 'banh-kem-bo-hoa-tulip-mau-tim'
    )
  }) || []

const TulipCollection = ({ imgData }) => {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  // const { addToCart } = useOrderItems()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  // chọn màu bánh
  const [selectedProduct, setSelectedProduct] = useState(tulipCakes[0])
  const [selectedVariant, setSelectedVariant] = useState(selectedProduct)

  const handleSelectedProduct = React.useCallback((productId) => {
    const product = find(tulipCakes, { id: productId })
    if (product) setSelectedProduct(product)
  }, [])
  const handleSelectedVariant = React.useCallback((variant) => {
    setSelectedVariant(variant)
  }, [])
  useEffect(() => {
    setSelectedVariant(selectedProduct?.variants[0])
  }, [selectedProduct])

  // const handleAddToCart = () => {
  //   tracker.addToCart({ value: selectedVariant.salePrice })
  //   addToCart({product:selectedProduct, selectedVariant})
  //   successToast('🎉 Thêm sản phẩm vào giỏ hàng thành công')
  // }
  return (
    <motion.div
      ref={ref}
      variants={revealVariants}
      initial="hidden"
      animate={controls}
    >
      <div tw="relative mb-2 z-10">
        <CakeContainer>
          <ColLeft reverse={true}>
            <CollectionSlider imgData={imgData} />
          </ColLeft>
          <ColRight reverse={true}>
            <DescComponent product={selectedProduct} />
            {selectedVariant ? (
              <>
                {/* color variant */}
                <div tw="flex flex-row justify-center items-center">
                  <div tw="font-savor-cake-secondary-quicksand font-semibold text-2xl pr-2 md:text-3xl">
                    Màu{' '}
                  </div>
                  <ColorVariant
                    productId="banh-kem-bo-hoa-tulip-mau-hong"
                    handleSelectedProduct={handleSelectedProduct}
                    selectedProduct={selectedProduct}
                  >
                    Hồng
                  </ColorVariant>
                  <ColorVariant
                    productId="banh-kem-bo-hoa-tulip-mau-tim"
                    handleSelectedProduct={handleSelectedProduct}
                    selectedProduct={selectedProduct}
                  >
                    Tím
                  </ColorVariant>
                </div>
                {/* end color variant */}
                <SizeComponent
                  product={selectedProduct}
                  selectedVariant={selectedVariant}
                  handleSelectedVariant={handleSelectedVariant}
                />
                <PriceComponent selectedVariant={selectedVariant} />
              </>
            ) : null}
            <Link to={'../'}>
              <OrderButton contentActive="Xem menu" />
            </Link>
          </ColRight>
        </CakeContainer>
      </div>
    </motion.div>
  )
}

export default TulipCollection

const ColorVariant = ({
  productId,
  handleSelectedProduct,
  selectedProduct,
  children,
}) => {
  return (
    <VariantStyled
      onClick={() => {
        handleSelectedProduct(productId)
      }}
      selectedVariant={get(selectedProduct, 'id') === productId ? true : false}
    >
      {children}
    </VariantStyled>
  )
}
